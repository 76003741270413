import url from "@/utils/url";

export default {
  id: "loop",
  title: "Loop Studios",
  subtitle: "Marketing Agency Landing Page",
  projectUrl: "https://loopstudios.com",
  color: {
    highlight: "#C7C4AE",
    highlightText: "#000000",
    light: "804E00",
    dark: "#000",
    gradient: "linear-gradient(45deg, #C7C4AE, #C7C4AE)",
  },
  thumb: {
    src: url("loop/loop-thumb.webp"),
    width: 500,
    height: 500,
  },
  hero: {
    src: url("loop/loop-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "An agency of creatives, connectors and strategists at the cusp of culture.",
    },
    {
      title: "Roles",
      list: ["Full-Stack", "UX Design"],
    },
    {
      title: "Tasks",
      text: "Develop the front end and deploy site to Digital Ocean.",
    },
    {
      title: "Designer",
      text: "Emily Brock",
    },
  ],
  breakdown: [
    {
      layout: "layout-ipad",
      bg: true,
      text: {
        title: "Animated Splashpage",
        summary:
          "The client was interested in animations what incorporated their logo into a slow reveal. Each image is preloaded in the background.",
      },
      media: {
        videos: [
          {
            deviceType: false,
            placeholder: url("mt/mt-desktop-placeholder.webp"),
            src: url("loop/loop-spash.webm"),
            alt: "Loop Animated Splash Page",
          },
        ],
        style: {
          clipPath: "inset(0 0 0 2px)",
          marginLeft: "var(--gutted)",
          marginRight: "var(--gutted)",
          marginBottom: "var(--gutted)",
        },
      },
    },
    // {
    //   media: {
    //     images: [
    //       [
    //         {
    //           src: url("loop/loop-faqs-mobile.webm"),
    //           alt: "Mobile Display",
    //           style: {
    //             paddingLeft: "0",
    //             marginLeft: "var(--gutted)",
    //             marginBottom: "var(--gutted)",
    //             width: "calc(100% - var(--gutted))",
    //           },
    //         },
    //       ],
    //       [
    //         {
    //           src: url("loop/loop-faqs-mobile-code.svg"),
    //           alt: "Search Logic",
    //           style: {
    //             paddingLeft: "var(--gutted)",
    //             marginBottom: "var(--gutted)",
    //           },
    //         },
    //       ],
    //     ],
    //   },
    //   layout: "layout-two-col",
    // },
  ],
};
