import Vue from "vue";
import Vuex from "vuex";
import about from "@/store/about";

// Projects
import archives from "./projects/archives";
import byte from "./projects/byte";
import crews from "./projects/crews";
import cumbres from "./projects/cumbres";
import da from "./projects/da";
import gts from "./projects/gts";
import mt from "./projects/mt";
import r11 from "./projects/r11";
import red from "./projects/red";
import rm from "./projects/rm";
import zzamong from "./projects/zzamong";
import loop from "./projects/loop";

// Theme
import defaultTheme from "./defaultTheme";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    about,
    projects: [
      loop,
      byte,
      gts,
      mt,
      red,
      r11,
      cumbres,
      crews,
      zzamong,
      da,
      rm,
      archives,
    ],
    routeFrom: {},
    themeColor: {},
    currentProject: {},
    dribbbleShots: setDefaultArray(4),
    instagram: setDefaultArray(7),
    wakatime: [],
  },
  mutations: {
    SET_THEME_COLOR(state, color) {
      state.themeColor = color;
    },
    SET_CURRENT_PROJECT(state, project) {
      state.currentProject = project;
    },
    SET_ROUTE_FROM(state, from) {
      state.routeFrom = from;
    },
    SET_DRIBBBLE(state, payload) {
      state.dribbbleShots = payload;
    },
    SET_INSTAGRAM(state, payload) {
      state.instagram = payload;
    },
    SET_WAKATIME(state, payload) {
      state.wakatime = payload;
    },
  },
  getters: {
    about: (state) => state.about,
    shortBio: (state) => state.about.shortBio,
    projects: (state) => state.projects.filter((p) => p.id !== "archives"),
    project: (state) => (id) => state.projects.find((p) => p.id === id),
    themeColor: (state) => state.themeColor,
    thumbSize: (state) => (id) => {
      for (let p = 0; p < state.projects.length; p++) {
        if (state.projects[p].id === id) {
          return p % 2 == 0;
        }
      }
    },
    currentProject: (state) => state.currentProject,
    routeFrom: (state) => state.routeFrom, // used to determine animation directions,
    dribbbleShots: (state) => state.dribbbleShots.slice(0, 5),
    isDribbblePlaceholder: (state) => state.dribbbleShots[0].id === 0,
    instagram: (state) => state.instagram && state.instagram.slice(0, 7),
    isInstagramPlaceholder: (state) =>
      state.instagram[0] && state.instagram[0].id === 0,
    wakatime: (state) => state.wakatime,
  },
  actions: {
    updateTheme({ commit }, colors = {}) {
      const { light, dark, highlight, highlightText, gradient } = Object.assign(
        defaultTheme(),
        colors
      );
      commit("SET_THEME_COLOR", {
        light,
        dark,
        highlight,
        highlightText,
        gradient,
      });
      const el = document.documentElement;
      el.style.setProperty("--theme-light", light);
      el.style.setProperty("--theme-dark", dark);
      el.style.setProperty("--theme-highlight", highlight);
      el.style.setProperty("--theme-highlight-text", highlightText);
      el.style.setProperty("--theme-gradient", gradient);
    },
    async getDribbble({ commit, getters }) {
      if (getters.isDribbblePlaceholder) {
        const url =
          "https://us-central1-mario-luevanos.cloudfunctions.net/api/dribbble";
        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            commit("SET_DRIBBBLE", data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getInstagram({ commit, getters }) {
      if (getters.isInstagramPlaceholder) {
        const url =
          "https://us-central1-mario-luevanos.cloudfunctions.net/api/instagram";
        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            if (!data.error) {
              commit("SET_INSTAGRAM", data);
            } else {
              console.log(data.error);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getWakaTime({ commit }) {
      const url =
        "https://us-central1-mario-luevanos.cloudfunctions.net/api/wakatime";
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          if (!data.error) {
            commit("SET_WAKATIME", data);
          } else {
            console.log(data.error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});

function setDefaultArray(length = 0) {
  return Array.from({ length }, (v, i) => ({ id: i }));
}
